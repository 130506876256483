import React from "react";
import LoadingSpin from "react-loading-spin";

function SpinLoading() {
  return (
    <LoadingSpin primaryColor="white" size="30px" animationDuration="1s" />
  );
}

export default SpinLoading;
