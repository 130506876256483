import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { General } from '../components';
import {
  Client,
  Codes,
  Crew,
  Dashboard,
  Feedback,
  Location,
  Login,
  Notification,
  OrderDetails,
  Orders,
  RolesAndRights,
  ServiceDetails,
  Services,
  TimeSlots,
  User,
} from '../screens';
import PrivateRoute from './ProtectedRoute';

function RootRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route
          path='/dashboard'
          element={
            <PrivateRoute>
              <General Component={Dashboard} />
            </PrivateRoute>
          }
        />
        <Route
          path='/user'
          element={
            <PrivateRoute>
              <General Component={User} />
            </PrivateRoute>
          }
        />
        <Route
          path='/crew'
          element={
            <PrivateRoute>
              <General Component={Crew} />
            </PrivateRoute>
          }
        />
        <Route
          path='/roles'
          element={
            <PrivateRoute>
              <General Component={RolesAndRights} />
            </PrivateRoute>
          }
        />
        <Route
          path='/territories'
          element={
            <PrivateRoute>
              <General Component={Location} />
            </PrivateRoute>
          }
        />
        <Route
          path='/time-slots'
          element={
            <PrivateRoute>
              <General Component={TimeSlots} />
            </PrivateRoute>
          }
        />
        <Route
          path='/territories/services'
          element={
            <PrivateRoute>
              <General Component={Services} />
            </PrivateRoute>
          }
        />
        <Route
          path='/territories/services/:id'
          element={
            <PrivateRoute>
              <General Component={ServiceDetails} />
            </PrivateRoute>
          }
        />
        <Route
          path='/orders'
          element={
            <PrivateRoute>
              <General Component={Orders} />
            </PrivateRoute>
          }
        />
        <Route
          path='/codes'
          element={
            <PrivateRoute>
              <General Component={Codes} />
            </PrivateRoute>
          }
        />
        <Route
          path='/orders/details'
          element={
            <PrivateRoute>
              <General Component={OrderDetails} />
            </PrivateRoute>
          }
        />
        <Route
          path='/notification'
          element={
            <PrivateRoute>
              <General Component={Notification} />
            </PrivateRoute>
          }
        />
        <Route
          path='/feedback'
          element={
            <PrivateRoute>
              <General Component={Feedback} />
            </PrivateRoute>
          }
        />
        <Route
          path='/client'
          element={
            <PrivateRoute>
              <General Component={Client} />
            </PrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default RootRouter;
